


















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import WorkSession from '@/models/WorkSession';
import Job from '@/models/Job';
import { namespace } from 'vuex-class';
import { jobStoreActions } from '@/stores/job.store';
import moment from 'moment';
import CleanTimeOccurrence from '@/models/CleanTimeOccurrence';
import RJAutocomplete from '@/components/shared/custom-vuetify/RJAutocomplete.vue';
import User from '@/models/User';

const JobStore = namespace('job');

@Component({
  components: {
    RJAutocomplete,
    RJTextField: () => import(
        '@/components/shared/custom-vuetify/RJTextField.vue'),
    RJSelect: () => import(
        '@/components/shared/custom-vuetify/RJSelect.vue'),
    MenuWithPicker: () => import(
        '@/components/shared/MenuWithPicker.component.vue'),
  },
})
export default class WorkSessionManageComponent extends Vue {
  @Prop({ default: () => [] })
  public workSessions!: WorkSession[];

  @Prop({ default: () => new Job() })
  public job?: Job;

  @Prop()
  public usersToManage?: User[];

  @JobStore.Action(jobStoreActions.CREATE_WORK_SESSIONS_ACTION)
  private createWorkSession!: (workSession: WorkSession) => Promise<WorkSession>;

  @JobStore.Action(jobStoreActions.UPDATE_WORK_SESSIONS_ACTION)
  private updateWorkSession!: (workSession: WorkSession) => Promise<WorkSession>;

  public get editMode() {
    return !!this.currentWorkSession.id;
  }

  @Prop({ default: () => new WorkSession() })
  public workSessionToEdit!: WorkSession;

  public currentWorkSession: WorkSession = new WorkSession();

  private workSessionUser: string = '';

  private get possibleUsers(): User[] {
    return this.usersToManage ?? (this.job?.cleanTime?.plannedUsers ?? []) as User[];
  }

  private get usersWithoutWorkSession(): User[] | undefined {
    return this.possibleUsers.filter((user) =>
        !this.job?.workSessions.some((session) => session.userId === (user as User).id)) as User[];
  }

  private dateMinMax: any = {
    min: undefined,
    max: undefined,
  };
  /**
   *  Time variables
   */
  private date: string = '';
  private dateEnd: string = '';
  private startTime: string = '';
  private endTime: string = '';
  private comment: string = '';

  public mounted() {
    if (!this.editMode && this.job && this.job.cleanTime?.plannedUsers?.length) {
      this.workSessionUser = (this.usersWithoutWorkSession?.[0] ?? this.possibleUsers[0]).id ?? '';
    }
    this.watchWorkSession();
    this.dateMinMax = {
      // work cannot be done before job starts
      min: moment(this.job!.cleanTimeOccurrence.start).startOf('day').format(this.$t('GENERAL.DATE_FORMATS.PICKER_DATE').toString()),
      // work cannot be done in the future
      max: moment().format(this.$t('GENERAL.DATE_FORMATS.PICKER_DATE').toString()),
    };
  }

  private async submit() {
    try {
      if (!this.workSessionUser && !this.editMode) {
        return this.$notifyInfoSimplified('TIMETRACKING.NOTIFICATIONS.NO_USER_SELECTED');
      }
      if (!(this.startTime && this.endTime) || this.startTime === this.endTime) {
        return this.$notifyInfoSimplified('TIMETRACKING.NOTIFICATIONS.INVALID_TIME');
      }

      const workSessionCopy = this.currentWorkSession.copy() as WorkSession;

      // set start- and endTime
      const timeWithDate = moment(this.date).format(this.$t('GENERAL.DATE_FORMATS.PICKER_DATE').toString()) + ' ' + this.startTime;
      workSessionCopy.startTime = moment(timeWithDate).toISOString();
      workSessionCopy.endTime = moment(timeWithDate).add(workSessionCopy.duration).toISOString();
      workSessionCopy.comment = this.comment;

      // endTime is on the next day if startTime > endTime
      if (moment(workSessionCopy.startTime).isAfter(moment(workSessionCopy.endTime))) {
        workSessionCopy.endTime = moment(workSessionCopy.endTime).add(1, 'days').toISOString();
      }

      let updatedWorkSession: WorkSession;
      if (this.editMode) {
        updatedWorkSession = await this.updateWorkSession(workSessionCopy);
      } else {
        workSessionCopy.userId = this.workSessionUser;
        workSessionCopy.cleanTimeId = this.job?.cleanTime?.id!;
        workSessionCopy.cleanTimeOccurrence = this.job?.cleanTimeOccurrence.start;
        updatedWorkSession = await this.createWorkSession(workSessionCopy);
        workSessionCopy.id = updatedWorkSession.id;
      }
      this.$notifySuccessSimplified(this.editMode ? 'TIMETRACKING.NOTIFICATIONS.EDIT_WORK_SESSION'
          : 'TIMETRACKING.NOTIFICATIONS.CREATE_WORK_SESSION');
      this.$emit('worksession-update', updatedWorkSession);
    } catch (e: any) {
      this.$notifyErrorSimplified('GENERAL_ERROR');
    }
  }

  private cancel() {
    this.$emit('cancel');
  }

  public getDurationHumanized(duration: number) {
    const d = moment.duration(duration);
    return `${d.hours()} ${this.$t('GENERAL.UNITS_SHORT.HOUR')}  ${(d.minutes() + '').padStart(2, '0')} ${this.$t('GENERAL.UNITS_SHORT.MINUTES')}.`;
  }

  /**
   * function to find the worksession if it exists, and autofill the inputs
   */
  @Watch('endTime')
  @Watch('startTime')
  // watch the time
  public inTimeChange() {
    // set the duration
    if (this.startTime && this.endTime) {
      this.currentWorkSession.setTimes(this.startTime, this.endTime, this.job ? this.job.cleanTimeOccurrence : new CleanTimeOccurrence());
    }
  }

  @Watch('workSessionUser')
  private watchWorkSession() {
    let session: any;
    if (this.workSessionToEdit.id) {
      session = this.workSessionToEdit.copy();
    } else {
      session = (this.workSessions.find((wSession) => typeof wSession.user === 'string' ?
              wSession.user === this.workSessionUser : wSession.userId === this.workSessionUser)) ??
          this.job?.workSessions.find((wSession) => wSession.userId === this.workSessionUser);
    }
    if (!session) {
      session = this.workSessionToEdit.copy();
    }
    this.currentWorkSession = session.copy() as WorkSession;

    this.comment = this.currentWorkSession.comment ?? '';
    this.date = (this.currentWorkSession.duration ? this.currentWorkSession.startTime : this.currentWorkSession.createdAt)
        || (this.job?.cleanTimeOccurrence?.start ?? new Date().toISOString());
    this.dateEnd = this.currentWorkSession.duration ? this.currentWorkSession.endTime : this.date;
    this.startTime = moment(this.date).format('HH:mm');
    this.endTime = this.currentWorkSession.duration ? moment(this.dateEnd).format('HH:mm') : '';

    this.inTimeChange();
  }
}
